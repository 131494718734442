import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import BaseFunctions, { defaultMissingImgURL } from '@/components/Utility/Base'
import CommonFunctions, { defaultImageURL } from '@/components/Utility/Common'
import MediasDataService from '@/services/MediasDataService'
import { Posts } from '@/services/PostsDataService'
import { Menus } from '@/services/MenusDataService'
import SysPost, { SysPostCategory, SysPostVersion } from '@/types/SysPost'
import SysMenu from '@/types/SysMenu'
import { Delta, Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import BlotFormatter from 'quill-blot-formatter'
// import { PropType } from 'vue'

/* export default defineComponent({
  name: 'AdminPosts',
  setup () {
    // component setup
  }
}) */

type postDataType = { postStatus: boolean; postTitle: string; postContents: string; postReleased: string; postReleasedDate: string; postReleasedTime: string; postCategory: string; postVersion: string; postVersioningData: SysPostVersion[]; cachedPostTitle: string; cachedPostContents: string; postSlug: string; originalPostSlug: string; }
type postCategoryDataType = { postCategoryStatus: boolean; postCategoryTitle: string; postCategoryDescription: string }
type dataReturnType = { indlaegs: any; indlaegversionering: any; indlaegkategori: any; filterStatusStringValue: string; postCategoryTitleValue: string; postSearchInputValue: string; postIndlaegs: any; imageUpload: any; quillToolbar: any; totalPages: number; tabValue: string; index: number; error: any; }

@Options({
  props: {
    msg: String,
    instanceType: Number
  },
  components: {
    QuillEditor
  }
})
export default class AdminPosts extends Vue {
  msg!: string
  instanceType!: number // 0 = regular posts, 1 = page posts
  private postTypeTextForm1 = ['Indlæg', 'Side']
  private postTypeTextForm2 = ['Indlæg', 'Sider']
  private postTypeTextForm3 = ['Et indlæg', 'En side']
  private postTypeTextForm4 = ['Indlægget', 'Siden']
  private postTypeTextForm5 = ['Nyt indlæg', 'Ny side']
  private postTypeTextForm6 = ['Et nyt indlæg', 'En ny side']
  private imageFiles: any[] = []
  private indlaegs: SysPost[] = []
  private tempPosts: SysPost[] = []
  private indlaegversionerings: SysPostVersion[] = []
  private indlaegkategoris: SysPostCategory[] = []
  private menus: SysMenu[] = []
  private indlaegversionering = {} as SysPostVersion
  private indlaegkategori = {} as SysPostCategory
  private tempPost = {} as SysPost
  private tempPostCategory = {} as SysPostCategory
  postData: postDataType = { postStatus: false, postTitle: '', postContents: '', postReleased: '', postReleasedDate: '', postReleasedTime: '', postCategory: '', postVersion: '0', postVersioningData: [], cachedPostTitle: '', cachedPostContents: '', postSlug: '', originalPostSlug: '' }
  postCategoryData: postCategoryDataType = { postCategoryStatus: false, postCategoryTitle: '', postCategoryDescription: '' }
  error: any = null
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Udgivet', 'Kladde']
  filterPostCategoryStringValue = { name: 'Vis alt', id: '0' }
  filterPostCategoryStringOptions: { name: string, id: string }[] = [{ name: 'Vis alt', id: '0' }]
  postCategoryTitleValue = 'Vælg her'
  postCategoryTitleOptions: string[] = []
  // menuLinkTitleValue = { name: 'Vælg her', value: '-1' }
  menuLinkTitleValue: { name: string; value: string }[] = []
  menuLinkTitleOptions: { name: string; value: string }[] = []
  // menuLinkTitleValue = { text: 'Vælg her', value: '-1' }
  // menuLinkTitleOptions: { text: string; value: string }[] = [{ text: 'Vælg her', value: '-1' }]
  postSearchInputValue = ''
  postSearchTerm = ''
  imageSearchInputValue = ''
  imageSearchTerm = ''
  toggleIconsSortingValue = 0
  private oldSort = ''
  lastCreatedClubId = 0
  private submitted = false
  private submitted2 = false
  private currentListSortingOrder = 'created_at:desc'
  private editPostCategoryNestedDrawerR = false
  private editPostCategoryNestedDrawerR2 = false
  private changePostVersionNestedDrawerR = false
  private editPostModal = false
  private deleteWarningModal = false
  private deleteCategoryWarningModal = false
  private createPostImageModal = false
  private editPostImageModal = false
  private imageResizeModal = true
  private deletePostId = 0
  private deleteCategoryPostId = 0
  private lastEditedPostId = 0
  private lastEditedPostCategoryId = 0
  private lastCreatedPostId = 0
  private lastChangedPostVersionPostId = 0
  private lastCreatedPostCategoryId = 0
  private lastChangedPostVersionCurrentVersion = 0
  private lastChangedPostVersionListObj = [{ id: <any>0, indlaeg_versionering_version: 0 }]
  private remainAtSameVersionWhenCreatingNewPostVersion = false
  private index = 0
  private currentPage = 1
  private totalPages = 0
  private currentImagePage = 1
  private totalNumberOfImagePages = 0
  private totalNumberOfPages = 0
  private totalNumberOfImages = 0
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  extraParameter = 'indlaeg_type=' + this.instanceType!.toString()
  private postIndlaegs: SysPost[] = []
  private columnName: string[] = ['title', 'author', 'category', 'published']
  private selectedImage = <any>[]
  private currentSelectedEditor = <any>[]
  // private imageUpload: any[] = []
  private imageUpload: any = []
  private tabValue = 'Vis og rediger ' + this.stringToLowerCase(this.postTypeTextForm1[this.instanceType])
  toolbarOptions: any[] = [ // Work in progress
    [{ header: [2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [], // Creates extra separation in the toolbar
    ['link', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [],
    [{ align: [] }],
    [],
    [{ font: [] }],
    [],
    [{ color: [] }, { background: [] }],
    [],
    ['clean']
  ]

  readonly name : string = 'AdminPosts'
  $Message: any

  data (): dataReturnType {
    return {
      indlaegs: this.indlaegs,
      indlaegversionering: this.indlaegversionering,
      indlaegkategori: this.indlaegkategori,
      filterStatusStringValue: this.filterStatusStringValue,
      postCategoryTitleValue: this.postCategoryTitleValue,
      postSearchInputValue: this.postSearchInputValue,
      postIndlaegs: this.postIndlaegs,
      imageUpload: this.imageUpload,
      quillToolbar: {
        modules: {
          toolbar: {
            container: this.toolbarOptions,
            handlers: {
              image: this.imageHandler
            }
          }
        }
      },
      totalPages: this.totalPages,
      tabValue: 'Vis og rediger ' + this.stringToLowerCase(this.postTypeTextForm1[this.instanceType]),
      index: this.index,
      error: this.error
    }
  }

  // $refs!: {
  declare $refs: {
    editPostEditor: typeof QuillEditor
    editPostCategoryEditor: typeof QuillEditor
    createPostEditor: typeof QuillEditor
    displayPostCategoryEditor: typeof QuillEditor
  }

  @Watch('filterPostCategoryStringValue')
  onCategoryFilterChange () : void {
    if (this.filterPostCategoryStringValue.id === '0') {
      this.extraParameter = 'indlaeg_type=' + this.instanceType!.toString()
    } else {
      this.extraParameter += '&indlaeg_kategori_id.id=' + this.filterPostCategoryStringValue.id
    }
    this.retrievePosts()
  }

  @Watch('deleteCategoryWarningModal')
  onDeleteCategoryWarningChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteCategoryPostId = 0
    }
  }

  @Watch('deleteWarningModal')
  onDeletePostWarningChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deletePostId = 0
    }
  }

  @Watch('filterStatusStringValue')
  onPostStatusChanged (statusValue: string, oldStatusValue: string) : void {
    // console.log('Filter status changed to: ' + statusValue)
    this.retrievePosts()
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrievePosts()
  }

  @Watch('editPostModal')
  onEditPostModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.postData = { postStatus: false, postTitle: '', postContents: '', postReleased: '', postReleasedDate: '', postReleasedTime: '', postCategory: '', postVersion: '0', postVersioningData: [], cachedPostTitle: '', cachedPostContents: '', postSlug: '', originalPostSlug: '' }
      this.postCategoryTitleValue = 'Vælg her'
    }
  }

  @Watch('editPostCategoryNestedDrawerR2')
  onEditPostCategoryDrawerToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.postCategoryData = { postCategoryStatus: false, postCategoryTitle: '', postCategoryDescription: '' }
      this.retrievePostCategories()
    }
  }

  // Onetime method to update all indlaeg_slug in the database
  // public updateAllSlugs () : void {
  //   let tempSlug = ''
  //   Posts.PostsDataService.getAll()
  //     .then((response) => {
  //       this.postIndlaegs = response.data

  //       for (const postIndlaeg of this.postIndlaegs) {
  //         if (postIndlaeg.indlaeg_slug === null) {
  //           tempSlug = postIndlaeg.indlaeg_versionerings_id[this.getIndexOfPostVersion(postIndlaeg.indlaeg_version, postIndlaeg)].indlaeg_versionering_titel.toLowerCase().replaceAll(' ', '-').replaceAll('æ', 'ae').replaceAll('ø', 'o').replaceAll('å', 'aa')

  //           const updatePostsData = {
  //             indlaeg_slug: tempSlug
  //           }
  //           console.log('UpdatePostsData ' + JSON.stringify(updatePostsData) + ' id= ' + Number(postIndlaeg.id).toString())
  //           Posts.PostsDataService.update(Number(postIndlaeg.id).toString(), updatePostsData)
  //             .then((response) => {
  //               console.log(response.data)
  //             })
  //             .catch((err) => {
  //               console.log('[updateAllSlugs] error in update() : ' + postIndlaeg.id + err)
  //             })
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       this.error = err
  //       console.log('[UpdateAllSlugs] error in getAll() : ' + err)
  //     })
  // }
  public getTimeOfDay (dateString: string) : string {
    return CommonFunctions.getHoursAndMinutes(dateString)
  }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public showColumn (column: string) : boolean {
    return this.columnName.includes(column)
  }

  public postDateFormatter (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/') + ' ' + new Date(dateString).toLocaleTimeString('da-DK', { hour: '2-digit', minute: '2-digit' }).replaceAll('.', ':')
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange
    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }
    this.retrievePosts()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber
    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }
    this.retrievePosts()
  }

  public deletePostWarning (id: number) : void {
    this.deletePostId = id
    this.deleteWarningModal = true
  }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'created_at:desc'
      this.oldSort = ''
      this.currentPage = 1
      this.postSearchInputValue = ''
      this.postSearchTerm = ''
    }
    if (sortBy === 'title') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'indlaeg_versionerings_id.indlaeg_versionering_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'indlaeg_versionerings_id.indlaeg_versionering_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'forfatter') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'indlaeg_forfatter.lastname:asc,indlaeg_forfatter.firstname:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'indlaeg_forfatter.lastname:asc,indlaeg_forfatter.firstname:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'kategori') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'indlaeg_kategori_id.indlaeg_kategori_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'indlaeg_kategori_id.indlaeg_kategori_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'udgivet') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'indlaeg_udgivet:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'indlaeg_udgivet:asc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'opdateret') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'updated_at:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'updated_at:asc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'visninger') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'indlaeg_visninger:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'indlaeg_visninger:asc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'version') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'indlaeg_version:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'indlaeg_version:desc'
        this.oldSort = ''
      }
    }

    this.retrievePosts()
  }

  public convertHTMLToText (htmlData: string) : string {
    return BaseFunctions.convertHTMLToText(htmlData)
  }

  public convertHTMLToTextShort (htmlData: string, cutoffSize = 40) {
    const getVal = this.convertHTMLToText(htmlData).trim()

    return getVal.slice(0, cutoffSize) + (getVal.length > cutoffSize + 1 ? ' ...' : '')
  }

  public stringToLowerCase (inputVal: string) : string {
    return inputVal.toLowerCase()
  }

  public generateValueArrayFromObjectArray (objArray: { name: string, value: string }[]) : string[] {
    const retVal = []
    for (const objItem of objArray) {
      retVal.push(objItem.value)
    }
    // console.log('Generated value array: ' + retVal)

    return retVal
  }

  public getPostsOfType (postType: number) : SysPost[] {
    // console.log('Get post type: ' + postType)
    return this.indlaegs.filter((element) => { return (element.indlaeg_type !== null ? (Number(element.indlaeg_type) === Number(postType)) : (Number(postType) === 0)) })
  }

  public getIndexOfPostVersion (postVersion: number, singlePostEntryObj: SysPost = this.tempPost) : number {
    // const retVal = this.indlaegs.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    const retVal = singlePostEntryObj.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    // console.log('Index of post version = ' + retVal + ' ; post versioning object = ' + JSON.stringify(singlePostEntryObj.indlaeg_versionerings_id))

    return (retVal === undefined ? -1 : retVal)
  }

  public getCategoryIdFromCategoryTitle (categoryTitle: string) : number | null {
    const retVal = this.indlaegkategoris.find(x => x.indlaeg_kategori_titel === categoryTitle)?.id
    // console.log('Category id found = ' + retVal)

    return (retVal === undefined ? 0 : retVal)
  }

  public getPostVersionIdCollectionOfPost (postId: number, postVersioningData: SysPostVersion[] = this.postData.postVersioningData) : any {
    // const retVal = [{ id: '5' }, { id: '8' }]
    const retVal = postVersioningData.map(({ id }) => ({ id }))
    // console.log('Collection of post version id = ' + JSON.stringify(retVal))

    return retVal
  }

  // Fill the menu title options array with data of the available menu items that are not a submenu
  public fillMenuLinkTitleOptionsData () : void {
    this.menuLinkTitleOptions = []
    for (const menuitem of this.menus) {
      if (!menuitem.menu_submenu) {
        this.menuLinkTitleOptions.push({ name: menuitem.menu_titel, value: (menuitem.id === undefined ? '0' : Number(menuitem.id).toString()) })
      }
    }
    // console.log('Menu titles = ' + JSON.stringify(this.menuLinkTitleOptions))
  }

  public forceRerenderPostsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public findPost () : void {
    // console.log('Search post.')
    this.postSearchTerm = this.postSearchInputValue
    this.retrievePosts()
  }

  public editPost (postId : number) : void {
    // console.log('Edit post with Id: ' + postId)
    this.lastEditedPostId = postId

    Posts.PostsDataService.get(postId.toString())
      .then((response) => {
        this.tempPost = response.data
        // console.log(response.data)

        const tempPostSlug = (this.tempPost.indlaeg_slug === null ? this.tempPost.indlaeg_versionerings_id[this.getIndexOfPostVersion(this.tempPost.indlaeg_version)].indlaeg_versionering_titel.toLowerCase().replaceAll(' ', '-').replaceAll('æ', 'ae').replaceAll('ø', 'o').replaceAll('å', 'aa') : this.tempPost.indlaeg_slug)
        this.postData = {
          postStatus: this.tempPost.indlaeg_status,
          postTitle: this.tempPost.indlaeg_versionerings_id[this.getIndexOfPostVersion(this.tempPost.indlaeg_version)].indlaeg_versionering_titel,
          postContents: this.tempPost.indlaeg_versionerings_id[this.getIndexOfPostVersion(this.tempPost.indlaeg_version)].indlaeg_versionering_indhold,
          postReleased: new Date(this.tempPost.indlaeg_udgivet).toISOString().substring(0, 16),
          postReleasedDate: new Date(this.tempPost.indlaeg_udgivet).toISOString().substring(0, 10),
          // postReleasedTime: this.getTimeOfDay(this.tempPost.indlaeg_udgivet),
          postReleasedTime: new Date(this.tempPost.indlaeg_udgivet).toLocaleTimeString('da-DK', { hour: '2-digit', minute: '2-digit' }).replaceAll('.', ':'),
          postCategory: this.tempPost.indlaeg_kategori_id.indlaeg_kategori_titel,
          postVersion: this.tempPost.indlaeg_version.toString(),
          postVersioningData: this.tempPost.indlaeg_versionerings_id,
          cachedPostTitle: this.tempPost.indlaeg_versionerings_id[this.getIndexOfPostVersion(this.tempPost.indlaeg_version)].indlaeg_versionering_titel,
          cachedPostContents: this.tempPost.indlaeg_versionerings_id[this.getIndexOfPostVersion(this.tempPost.indlaeg_version)].indlaeg_versionering_indhold,
          postSlug: tempPostSlug,
          originalPostSlug: tempPostSlug
        }

        this.postCategoryTitleValue = this.tempPost.indlaeg_kategori_id.indlaeg_kategori_titel
        // this.menuLinkTitleValue = { name: this.tempPost.menus_id.menu_titel, value: this.tempPost.menus_id.id }
        this.menuLinkTitleValue = []
        for (const menuitem of this.tempPost.menus_id) {
          this.menuLinkTitleValue.push({ name: menuitem.menu_titel, value: Number(menuitem.id).toString() })
        }

        const interval = setInterval(() => {
          if (this.$refs.editPostEditor) {
            clearInterval(interval)
            this.$refs.editPostEditor.setContents(this.postData.postContents, 'user')
          }
        }, 100)

        /* if (!this.postData.postContents.trim().startsWith('<p')) {
          this.postData.postContents = '<pre>' + this.postData.postContents + '</pre>'
        } */
        this.editPostModal = true
        // console.log(this.postData.postReleasedTime)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelPostEdit () : void {
    this.postData = { postStatus: false, postTitle: '', postContents: '', postReleased: '', postReleasedDate: '', postReleasedTime: '', postCategory: '', postVersion: '0', postVersioningData: [], cachedPostTitle: '', cachedPostContents: '', postSlug: '', originalPostSlug: '' }
    this.postCategoryTitleValue = 'Vælg her'
  }

  public statusPost (postId : number) : void {
    console.log('Change status of post with Id: ' + postId)

    Posts.PostsDataService.get(postId.toString())
      .then((response) => {
        this.tempPost = response.data
        // console.log(response.data)

        const updatePostData = {
          indlaeg_status: !this.tempPost.indlaeg_status
        }

        // console.log('New status of post = ' + updatePostData.indlaeg_status)

        Posts.PostsDataService.update(postId.toString(), updatePostData)
          .then((response) => {
            // this.lastUpdatedPostId = response.data.id
            // console.log(response.data)
            const indlaegsIndexToUpdateStatus = this.indlaegs.findIndex(x => x.id === postId)
            this.indlaegs[indlaegsIndexToUpdateStatus].indlaeg_status = updatePostData.indlaeg_status
          })
          .catch((err) => {
            this.error = err
            console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deletePost (postId : number) : void {
    // console.log('Delete post with Id: ' + postId)

    Posts.PostsDataService.delete(postId.toString())
      .then((response) => {
        // console.log(response.data)
        this.retrievePosts()
        this.deleteWarningModal = false
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public changePostVersion (postId : number) : void {
    // console.log('Change post version with Id: ' + postId)
    this.lastChangedPostVersionPostId = postId

    Posts.PostsDataService.get(postId.toString())
      .then((response) => {
        this.tempPost = response.data
        // console.log(response.data)
        this.indlaegversionerings = this.tempPost.indlaeg_versionerings_id.reverse()
        this.lastChangedPostVersionCurrentVersion = this.tempPost.indlaeg_version
        this.lastChangedPostVersionListObj = this.indlaegversionerings.map(({ id, indlaeg_versionering_version }) => ({ id, indlaeg_versionering_version }))
        // console.log('lastChangedPostVersionListObj = ' + JSON.stringify(this.lastChangedPostVersionListObj) + '; max version value = ' + Math.max(...this.lastChangedPostVersionListObj.map(o => Number(o.indlaeg_versionering_version)), 1))
        this.changePostVersionNestedDrawerR = true
      })
      .catch((err) => {
        this.error = err
        this.changePostVersionNestedDrawerR = false
      })
  }

  public changeToPostVersion (postVersionId: number, postVersion: number) : void {
    // console.log('Change post version to: ' + postVersion + ' with version Id: ' + postVersionId)

    if (postVersion > 0) {
      const updatePostData = {
        indlaeg_version: postVersion
      }

      Posts.PostsDataService.update(this.lastChangedPostVersionPostId.toString(), updatePostData)
        .then((response) => {
          // this.lastUpdatedPostId = response.data.id
          // console.log(response.data)
          this.lastChangedPostVersionCurrentVersion = response.data.indlaeg_version === undefined || response.data.indlaeg_version === null || response.data.indlaeg_version === 0 ? this.lastChangedPostVersionCurrentVersion : response.data.indlaeg_version
          this.retrievePosts()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
          this.changePostVersionNestedDrawerR = false
        })
    }
  }

  public deletePostVersion (postVersionId: number, postVersion: number) : void {
    // console.log('Delete post version with Id: ' + postVersionId)

    // If current post version is being deleted, then update current post version to another most recent version than the one that is to be deleted
    const maxPostVersionAfterDelete = Math.max(...this.lastChangedPostVersionListObj.filter(o => o.id !== postVersionId).map(o => Number(o.indlaeg_versionering_version)), 1)
    // console.log('Post version array object filtered: ' + JSON.stringify(this.lastChangedPostVersionListObj.filter(o => o.id !== postVersionId)))
    // console.log('Post version array object size: ' + this.lastChangedPostVersionListObj.length)
    // console.log('Maximum post version value found:' + maxPostVersionAfterDelete)

    if (this.lastChangedPostVersionListObj.length > 1) {
      // First set a new current post version for the post, that is not the post version that will be deleted
      const updatePostData = {
        indlaeg_version: maxPostVersionAfterDelete
      }

      Posts.PostsDataService.update(this.lastChangedPostVersionPostId.toString(), updatePostData)
        .then((response) => {
          // this.lastUpdatedPostId = response.data.id
          // console.log(response.data)
          this.lastChangedPostVersionCurrentVersion = response.data.indlaeg_version === undefined || response.data.indlaeg_version === null || response.data.indlaeg_version === 0 ? this.lastChangedPostVersionCurrentVersion : response.data.indlaeg_version
          // this.retrievePosts()

          if (response.data.indlaeg_version !== undefined && response.data.indlaeg_version !== null && response.data.indlaeg_version > 0) {
            // Now delete the chosen post version
            Posts.PostVersionsDataService.delete(postVersionId.toString())
              .then((response) => {
                // console.log(response.data)

                // Update the information of the currently used array of objects for the post version data
                // Find the index within the post version array of objects, and remove one element from that index position
                const postIndexToBeDeleted = this.indlaegversionerings.findIndex(x => x.id === postVersionId)
                // console.log('Post index to be deleted: ' + postIndexToBeDeleted)
                this.indlaegversionerings.splice(postIndexToBeDeleted, 1)

                this.retrievePosts()
              })
              .catch((err) => {
                this.error = err
                // console.log(err)
                this.changePostVersionNestedDrawerR = false
              })
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
          this.changePostVersionNestedDrawerR = false
        })
    }
  }

  public editPostCategory (postCategoryId: number) : void {
    // console.log('Edit post category with Id: ' + postCategoryId)
    this.lastEditedPostCategoryId = postCategoryId
    this.editPostCategoryNestedDrawerR2 = true

    Posts.PostCategoriesDataService.get(postCategoryId.toString())
      .then((response) => {
        this.tempPostCategory = response.data
        // console.log(response.data)

        this.postCategoryData = { postCategoryStatus: this.tempPostCategory.indlaeg_kategori_status, postCategoryTitle: this.tempPostCategory.indlaeg_kategori_titel, postCategoryDescription: this.tempPostCategory.indlaeg_kategori_beskrivelse }
        const interval = setInterval(() => {
          if (this.$refs.editPostCategoryEditor) {
            clearInterval(interval)
            this.$refs.editPostCategoryEditor.setContents(this.postCategoryData.postCategoryDescription, 'user')
          }
        }, 100)
      })
      .catch((err) => {
        this.error = err
        this.editPostCategoryNestedDrawerR2 = false
      })
  }

  public statusPostCategory (postCategoryId: number) : void {
    // console.log('Change status of post category with Id: ' + postCategoryId)

    Posts.PostCategoriesDataService.get(postCategoryId.toString())
      .then((response) => {
        this.tempPostCategory = response.data
        // console.log(response.data)

        const updatePostCategoryData = {
          indlaeg_kategori_status: !this.tempPostCategory.indlaeg_kategori_status
        }

        // console.log('New status of post category = ' + updatePostCategoryData.indlaeg_kategori_status)

        Posts.PostCategoriesDataService.update(postCategoryId.toString(), updatePostCategoryData)
          .then((response) => {
            // this.lastUpdatedPostId = response.data.id
            // console.log(response.data)
            this.retrievePostCategories()
          })
          .catch((err) => {
            this.error = err
            console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deletePostCategoryWarning (id: number) : void {
    this.deleteCategoryPostId = id
    this.deleteCategoryWarningModal = true
  }

  public deletePostCategory (postCategoryId: number) : void {
    // First ensure that the categori does not have id 1
    if (postCategoryId > 2) {
      // Then move any post with the category to be deleted to the category "Nyheder" with id: 2
      // console.log('Reassigning post with the deleted category to category id: 2')
      Posts.PostsDataService.getAll('', null, `indlaeg_kategori_id.id=${postCategoryId.toString()}`)
        .then((response) => {
          this.tempPosts = response.data

          if (this.tempPosts.length > 0) {
            for (const postItem of this.tempPosts) {
              const updatePostData = {
                indlaeg_kategori_id: 2
              }
              Posts.PostsDataService.update(Number(postItem.id).toString(), updatePostData)
                .then((response) => {
                  // console.log(response.data)
                })
                .catch((err) => {
                  this.error = err
                })
            }
          }
        })
        .catch((err) => {
          this.error = err
        })
        .then(() => {
          // console.log('Delete post category with Id: ' + postCategoryId)
          Posts.PostCategoriesDataService.delete(postCategoryId.toString())
            .then((response) => {
              // console.log(response.data)
              this.retrievePostCategories()
              this.retrievePosts()
              this.deleteCategoryWarningModal = false
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
              this.editPostCategoryNestedDrawerR = false
            })
        })
    }
  }

  public createPost () : void {
    // console.log('Creating a new post data with the API.')

    let createPostData = {}
    let createPostVersioningData = {}
    let tempPostSlug = ''
    let tempDateString = ''
    const categoryIdSelected = Number(this.instanceType) === 1 ? 1 : this.getCategoryIdFromCategoryTitle(this.postCategoryTitleValue)

    if (this.postData.postReleasedDate !== '' || this.postData.postReleasedTime !== '') {
      const toDaysDate = new Date().toISOString()
      tempDateString = (this.postData.postReleasedDate === '' ? toDaysDate.split('T')[0] : this.postData.postReleasedDate) + 'T' + (this.postData.postReleasedTime === '' ? toDaysDate.split('T')[1] : this.postData.postReleasedTime)

      console.log(tempDateString)
    }

    if (this.postData.postSlug === null || this.postData.postSlug === '') {
      tempPostSlug = this.postData.postTitle
    } else {
      tempPostSlug = this.postData.postSlug
    }
    // console.log('postContents = ' + this.postData.postContents)

    if ((this.postCategoryTitleValue !== 'Vælg her' || Number(this.instanceType) === 1) && categoryIdSelected !== null &&
        categoryIdSelected > 0 && this.postData.postTitle.length > 1 && this.postData.postContents.length > 1) {
      createPostVersioningData = {
        indlaeg_versionering_version: Number(this.postData.postVersion) + 1,
        indlaeg_versionering_titel: this.postData.postTitle,
        indlaeg_versionering_indhold: this.postData.postContents
      }
      Posts.PostsDataService.getAll('', null, `indlaeg_slug=${this.convertToSlug(tempPostSlug)}`)
        .then((response) => {
          if (response.data.length > 0) {
            this.$Message.danger({ text: 'Slugget er allerede i brug. Enten vælg en ny titel, eller ændre slugget' })
          } else {
            // console.log('Attempt to create new post with Id: ' + (1 + this.lastCreatedPostId))

            Posts.PostVersionsDataService.create(createPostVersioningData)
              .then((response) => {
                const lastCreatedPostVersionId = response.data.id
                // console.log(response.data)
                createPostData = {
                  indlaeg_status: this.postData.postStatus,
                  indlaeg_udgivet: (tempDateString === '' ? new Date() : new Date(tempDateString)),
                  // indlaeg_udgivet: (this.postData.postReleased !== '' ? this.postData.postReleased : new Date()),
                  indlaeg_version: Number(this.postData.postVersion) + 1,
                  indlaeg_forfatter: 2, // Fixme: should be id of current logged in user
                  indlaeg_kategori_id: categoryIdSelected,
                  indlaeg_versionerings_id: [{ id: lastCreatedPostVersionId }],
                  indlaeg_visninger: 0,
                  indlaeg_type: Number(this.instanceType),
                  indlaeg_slug: this.convertToSlug(tempPostSlug),
                  menus_id: (Number(this.instanceType) !== 0 ? this.generateValueArrayFromObjectArray(this.menuLinkTitleValue) : null)
                  /* menus_id: (Number(this.instanceType) !== 0 || this.menuLinkTitleValue.name !== 'Vælg her' ? this.menuLinkTitleValue.value : 0) */
                }

                Posts.PostsDataService.create(createPostData)
                  .then((response) => {
                    this.lastCreatedPostId = response.data.id
                    console.log(response.data)

                    this.submitted = true
                    this.newCreatePost()
                    this.$Message.success({ text: this.postTypeTextForm4[this.instanceType] + ' er oprettet' })
                    this.tabValue = 'Vis og rediger ' + this.stringToLowerCase(this.postTypeTextForm1[this.instanceType])
                  })
                  .catch((err) => {
                    this.error = err
                    console.error(err)

                    alert(this.error + '\nDet kan skyldes at slugget ikke er unikt')
                    // this.submitted = true
                  })
              })
              .catch((err) => {
                this.error = err
                console.log(err)

                this.submitted = true
              })
          }
        })
        .catch((err) => {
          this.error = err
          console.log(err)
        })
    }
  }

  public newCreatePost () : void {
    this.submitted = false
    this.postData = { postStatus: false, postTitle: '', postContents: '', postReleased: '', postReleasedDate: '', postReleasedTime: '', postCategory: '', postVersion: '0', postVersioningData: [], cachedPostTitle: '', cachedPostContents: '', postSlug: '', originalPostSlug: '' }
    this.postCategoryTitleValue = 'Vælg her'
    this.retrievePosts()
  }

  public createPostCategory () : void {
    // console.log('Creating a new post category data with the API.')

    if (this.postCategoryData.postCategoryTitle.length > 1) {
      const createPostCategoryData = {
        indlaeg_kategori_status: this.postCategoryData.postCategoryStatus,
        indlaeg_kategori_titel: this.postCategoryData.postCategoryTitle,
        indlaeg_kategori_beskrivelse: this.postCategoryData.postCategoryDescription
      }

      // console.log('Attempt to create new post with Id: ' + (1 + this.lastCreatedPostId))

      Posts.PostCategoriesDataService.create(createPostCategoryData)
        .then((response) => {
          this.lastCreatedPostCategoryId = response.data.id
          // console.log(response.data)

          this.submitted2 = true
          this.newCreatePostCategory()
          this.$Message.success({ text: 'Kategorien er blevet oprettet' })
          this.tabValue = 'Vis og rediger kategorier'
        })
        .catch((err) => {
          this.error = err
          // console.log(err)

          this.submitted2 = true
        })
    }
  }

  public newCreatePostCategory () : void {
    this.submitted2 = false
    this.postCategoryData = { postCategoryStatus: false, postCategoryTitle: '', postCategoryDescription: '' }
    this.retrievePostCategories()
    this.retrievePosts()
  }

  public updatePost () : boolean {
    // console.log('Updating data of post with Id: ' + this.lastEditedPostId)
    let createPostData = {}
    let createPostVersioningData = {}
    let tempPostSlug = ''
    const categoryIdSelected = Number(this.instanceType) === 1 ? 1 : this.getCategoryIdFromCategoryTitle(this.postCategoryTitleValue)
    const tempDateString = new Date(this.postData.postReleasedDate + 'T' + this.postData.postReleasedTime).toISOString()
    // console.log(tempDateString)

    if (this.postData.postSlug === null || this.postData.postSlug === '' || this.postData.postSlug === undefined) {
      tempPostSlug = this.postData.postTitle
    } else {
      tempPostSlug = this.postData.postSlug
    }

    if (!(this.postCategoryTitleValue !== 'Vælg her' || Number(this.instanceType === 1)) ||
      !(categoryIdSelected !== null) ||
      !(categoryIdSelected > 0) ||
      !(this.postData.postTitle.trim().length > 1) ||
      !(this.postData.postContents.trim().length > 1)) {
      this.$Message.danger({ text: 'Fejl: Indhold mangler' })

      return false
    }
    const makeNewPostVersion = this.postData.postTitle !== this.postData.cachedPostTitle || this.postData.postContents !== this.postData.cachedPostContents
    const postVersionIdCollectionOfPost = this.getPostVersionIdCollectionOfPost(this.lastEditedPostId)
    const maxPostVersionForCurrentPost = Math.max(...this.postData.postVersioningData.map(o => Number(o.indlaeg_versionering_version)), 1)

    // console.log('postVersionIdCollectionOfPost = ' + JSON.stringify(postVersionIdCollectionOfPost))

    if (makeNewPostVersion) {
      createPostVersioningData = {
        indlaeg_versionering_version: maxPostVersionForCurrentPost + 1,
        indlaeg_versionering_titel: this.postData.postTitle,
        indlaeg_versionering_indhold: this.postData.postContents
      }
    } else {
      createPostData = {
        indlaeg_status: this.postData.postStatus,
        indlaeg_udgivet: tempDateString,
        // indlaeg_udgivet: this.postData.postReleased,
        // indlaeg_version: Number(this.postData.postVersion),
        indlaeg_forfatter: 2, // Fixme: should be id of current logged in user
        indlaeg_kategori_id: categoryIdSelected,
        indlaeg_slug: this.convertToSlug(tempPostSlug),
        menus_id: (Number(this.instanceType) !== 0 ? this.generateValueArrayFromObjectArray(this.menuLinkTitleValue) : null)
        /* menus_id: (Number(this.instanceType) !== 0 || this.menuLinkTitleValue.name !== 'Vælg her' ? this.menuLinkTitleValue.value : 0) */
        // indlaeg_slug: this.convertToSlug(tempPostSlug)
      }
    }

    if (this.postData.originalPostSlug !== tempPostSlug) {
      Posts.PostsDataService.getAll('', null, `indlaeg_slug=${this.convertToSlug(tempPostSlug)}`)
        .then((response) => {
          if (response.data.length > 0) {
            this.$Message.danger({ text: 'Slugget skal være unikt. Angiv et nyt, og forsøg igen' })

            return false
          } else {
            // console.log('Attempt to update post with Id: ' + this.lastEditedPostId)

            if (makeNewPostVersion) {
              Posts.PostVersionsDataService.create(createPostVersioningData)
                .then((response) => {
                  const lastCreatedPostVersionId = response.data.id
                  // console.log(response.data)

                  postVersionIdCollectionOfPost.push({ id: lastCreatedPostVersionId })
                  createPostData = {
                    indlaeg_status: this.postData.postStatus,
                    indlaeg_udgivet: this.postData.postReleased,
                    indlaeg_version: (this.remainAtSameVersionWhenCreatingNewPostVersion ? Number(this.postData.postVersion) : maxPostVersionForCurrentPost) + 1,
                    indlaeg_forfatter: 2, // Fixme: should be id of current logged in user
                    indlaeg_kategori_id: categoryIdSelected,
                    indlaeg_versionerings_id: postVersionIdCollectionOfPost,
                    // indlaeg_visninger: 0,
                    indlaeg_slug: tempPostSlug,
                    menus_id: (Number(this.instanceType) !== 0 ? this.generateValueArrayFromObjectArray(this.menuLinkTitleValue) : null)
                    /* menus_id: (Number(this.instanceType) !== 0 || this.menuLinkTitleValue.name !== 'Vælg her' ? this.menuLinkTitleValue.value : 0) */
                    // indlaeg_slug: tempPostSlug
                  }

                  Posts.PostsDataService.update(this.lastEditedPostId.toString(), createPostData)
                    .then((response) => {
                      // this.lastUpdatedPostId = response.data.id
                      // console.log(response.data)

                      this.retrievePosts()
                      this.editPostModal = false

                      return true
                    })
                    .catch((err) => {
                      this.error = err
                      // console.log(err)
                      // this.editPostModal = false
                    })
                })
                .catch((err) => {
                  this.error = err
                  // console.log(err)
                  // this.editPostModal = false
                })
            } else {
              Posts.PostsDataService.update(this.lastEditedPostId.toString(), createPostData)
                .then((response) => {
                  // this.lastUpdatedPostId = response.data.id
                  // console.log(response.data)

                  this.editPostModal = false
                  this.retrievePosts()

                  return true
                })
                .catch((err) => {
                  this.error = err
                  // console.log(err)
                  // this.retrievePosts()
                  // alert(this.error + '\nDet kan skyldes at slugget ikke er unikt')
                  // this.editPostModal = false
                })
            }
          }
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      if (makeNewPostVersion) {
        Posts.PostVersionsDataService.create(createPostVersioningData)
          .then((response) => {
            const lastCreatedPostVersionId = response.data.id
            // console.log(response.data)

            postVersionIdCollectionOfPost.push({ id: lastCreatedPostVersionId })
            createPostData = {
              indlaeg_status: this.postData.postStatus,
              indlaeg_udgivet: this.postData.postReleased,
              indlaeg_version: (this.remainAtSameVersionWhenCreatingNewPostVersion ? Number(this.postData.postVersion) : maxPostVersionForCurrentPost) + 1,
              indlaeg_forfatter: 2, // Fixme: should be id of current logged in user
              indlaeg_kategori_id: categoryIdSelected,
              indlaeg_versionerings_id: postVersionIdCollectionOfPost,
              // indlaeg_visninger: 0,
              indlaeg_slug: tempPostSlug,
              menus_id: (Number(this.instanceType) !== 0 ? this.generateValueArrayFromObjectArray(this.menuLinkTitleValue) : null)
              /* menus_id: (Number(this.instanceType) !== 0 || this.menuLinkTitleValue.name !== 'Vælg her' ? this.menuLinkTitleValue.value : 0) */
              // indlaeg_slug: tempPostSlug
            }

            Posts.PostsDataService.update(this.lastEditedPostId.toString(), createPostData)
              .then((response) => {
                // this.lastUpdatedPostId = response.data.id
                // console.log(response.data)

                this.retrievePosts()
                this.editPostModal = false

                return true
              })
              .catch((err) => {
                this.error = err
                // console.log(err)
                // this.editPostModal = false
              })
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        Posts.PostsDataService.update(this.lastEditedPostId.toString(), createPostData)
          .then((response) => {
            // this.lastUpdatedPostId = response.data.id
            // console.log(response.data)

            this.editPostModal = false
            this.retrievePosts()

            return true
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
            // this.retrievePosts()
            // this.editPostModal = false
          })
      }
    }

    return false
  }

  public updateCategoryPost (inputVal: number) : void {
    // console.log('Updating data of post category with Id: ' + this.lastEditedPostCategoryId)

    const createPostCategoryData = {
      indlaeg_kategori_titel: this.postCategoryData.postCategoryTitle,
      indlaeg_kategori_beskrivelse: this.postCategoryData.postCategoryDescription,
      indlaeg_kategori_status: this.postCategoryData.postCategoryStatus
    }

    // console.log('Attempt to update post category with Id: ' + (1 + this.lastCreatedPostId))

    Posts.PostCategoriesDataService.update(this.lastEditedPostCategoryId.toString(), createPostCategoryData)
      .then((response) => {
        // this.lastUpdatedPostCategoryId = response.data.id
        // console.log(response.data)

        this.retrievePostCategories()
        if (inputVal > 0) {
          this.editPostCategoryNestedDrawerR2 = false
        }
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public retrieveMenus () : void {
    // console.log('Fetching menu data from API.')

    Menus.MenusDataService.getAll(this.currentListSortingOrder)
      .then((response) => {
        this.menus = response.data
        // console.log(response.data)

        this.fillMenuLinkTitleOptionsData()
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public retrievePostCategories () : void {
    // console.log('Fetching post category data from API.')

    Posts.PostCategoriesDataService.getAll()
      .then((response) => {
        this.indlaegkategoris = response.data
        // console.log(response.data)

        this.postCategoryTitleOptions = []
        this.postCategoryTitleOptions.push('Vælg her')
        this.filterPostCategoryStringOptions = [{ name: 'Vis alt', id: '0' }]
        for (const indlaegkategori of this.indlaegkategoris) {
          this.postCategoryTitleOptions.push(indlaegkategori.indlaeg_kategori_titel)
          this.filterPostCategoryStringOptions.push({ name: indlaegkategori.indlaeg_kategori_titel, id: Number(indlaegkategori.id).toString() })
        }
        // console.log('Post category titles = ' + this.postCategoryTitleOptions)

        // this.forceRerenderPostsList()
        // this.$forceUpdate()
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public retrievePosts () : void {
    console.log('Fetching posts data from API.')

    let countFilter = ''

    if (this.filterStatusStringValue === 'Vis alt') {
      countFilter = this.extraParameter
    } else if (this.filterStatusStringValue === 'Udgivet') {
      countFilter = 'indlaeg_status=true&' + this.extraParameter
    } else {
      countFilter = 'indlaeg_status=false&' + this.extraParameter
    }

    Posts.PostsDataService.getCount(countFilter)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        // console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries: ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })

    if (this.postSearchTerm.length > 0) {
      Posts.PostsDataService.findBySearchTerm((this.filterStatusStringValue === 'Udgivet'), (this.filterStatusStringValue === 'Vis alt'), this.postSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
        .then((response) => {
          this.indlaegs = response.data
          // console.log(response.data)

          this.forceRerenderPostsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        Posts.PostsDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
          .then((response) => {
            this.indlaegs = response.data
            // console.log(response.data)

            this.forceRerenderPostsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        Posts.PostsDataService.findByPostStatus((this.filterStatusStringValue === 'Udgivet'), this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
          .then((response) => {
            this.indlaegs = response.data
            // console.log(response.data)

            this.forceRerenderPostsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }

    CommonFunctions.scrollToTop()
  }

  // All the methods related to image handeling both for editPost and createPost
  @Watch('editPostImageModal')
  onEditImageModalChange (toggleValue: boolean) {
    if (toggleValue === false) {
      this.imageSearchInputValue = ''
      this.imageSearchTerm = ''
      this.imageUpload = []
      this.selectedImage = []
    }
  }

  @Watch('createPostImageModal')
  onCreateImageModalChange (toggleValue: boolean) {
    if (toggleValue === false) {
      this.imageSearchInputValue = ''
      this.imageSearchTerm = ''
      this.imageUpload = []
      this.selectedImage = []
    }
  }

  @Watch('imageUpload')
  onImageUploadChange () {
    if (this.imageUpload !== undefined && JSON.stringify(<File> this.imageUpload.size) !== null && Number((<File> this.imageUpload).size) > 0) {
      const fd: FormData = new FormData()

      // fd.append('files', this.imageUpload[0])
      fd.append('files', this.imageUpload)

      MediasDataService.upload(fd)
        .then((response) => {
          console.log(response.data)

          this.retrieveImages()
          this.imageUpload = []
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  imageHandler () : void {
    if (this.$refs.createPostEditor.getQuill().getSelection() === null) {
      this.editPostImageModal = true
      this.currentSelectedEditor = this.$refs.editPostEditor.getQuill().getSelection()
    } else {
      this.createPostImageModal = true
      this.currentSelectedEditor = this.$refs.createPostEditor.getQuill().getSelection()
    }
    this.retrieveImages()
  }

  public getImage (imageData: any) : string {
    const imageURL = defaultImageURL + imageData.url
    if (imageURL === undefined || imageURL === null) {
      return defaultMissingImgURL
    }
    return imageURL
  }

  public insertImage () : void {
    let imageURL = ''
    if (this.selectedImage !== null) {
      // console.log(this.selectedImage.url)

      imageURL = defaultImageURL + this.selectedImage.url
      if (this.editPostImageModal === true) {
        this.$refs.editPostEditor.getQuill().insertEmbed(this.currentSelectedEditor.index, 'image', imageURL)
        this.editPostImageModal = false
      } else {
        this.$refs.createPostEditor.getQuill().insertEmbed(this.currentSelectedEditor.index, 'image', imageURL)
        this.createPostImageModal = false
      }
    }
  }

  public findImage () : void {
    // console.log('Searching images')
    this.imageSearchTerm = this.imageSearchInputValue
    this.retrieveImages()
  }

  public turnToNewImagePage (pageChange: number) {
    this.currentImagePage += pageChange
    if (this.currentImagePage < 1) {
      this.currentImagePage = 1
    }
    if (this.currentImagePage > this.totalNumberOfImagePages) {
      this.currentImagePage = this.totalNumberOfImagePages
    }
    this.retrieveImages()
  }

  public jumpToImagePage (pageNumber: number) {
    this.currentImagePage = pageNumber
    if (this.currentImagePage < 1) {
      this.currentImagePage = 1
    }
    if (this.currentImagePage > this.totalNumberOfImagePages) {
      this.currentImagePage = this.totalNumberOfImagePages
    }
    this.retrieveImages()
  }

  public resetSearch () : void {
    // console.log('Reseting search')
    this.imageSearchInputValue = ''
    this.imageSearchTerm = ''
    this.retrieveImages()
  }

  public retrieveImages () : void {
    // console.log('Counting total numbers of images')

    MediasDataService.getCount('mime_contains=image')
      .then((response) => {
        this.totalNumberOfImages = response.data
        this.totalNumberOfImagePages = Math.ceil(this.totalNumberOfImages / 15)
        // console.log(this.currentImagePage + ' of ' + this.totalNumberOfImagePages + ' total number of entries: ' + this.totalNumberOfImages)
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        // console.log('Fetching images from API.')
        if (this.imageSearchTerm.length > 0) {
          MediasDataService.findBySearchTerm(this.imageSearchTerm, 'updated_at:desc', { slicemode: 1, start: 0, limit: -1, page: (this.currentImagePage - 1), pagesize: 15, totalcount: this.totalNumberOfImages }, 'mime_contains=image')
            .then((response) => {
              this.imageFiles = response.data
            })
            .catch((err) => {
              this.error = err
            })
        } else {
          MediasDataService.getAll('updated_at:desc', { slicemode: 1, start: 0, limit: -1, page: (this.currentImagePage - 1), pagesize: 15, totalcount: this.totalNumberOfImages }, 'mime_contains=image')
            .then((response) => {
              this.imageFiles = response.data
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveMenus()
        this.retrievePostCategories()
        this.retrievePosts()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
